/* eslint-disable no-unused-vars */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import desktopImage from "../../../../assets/images/home-images/Landing Page hero image.1.png";
import mobileImage from "../../../../assets/images/home-images/Landing Page hero image - Mobile.png";

function GeneralInformation() {
  const matches = useMediaQuery("(min-width:900px)");

  return (
    <Box backgroundColor="#002B4D">
      <Container>
        <Grid
          container
          direction={{
            xl: "row",
            lg: "row",
            md: "column-reverse",
            sm: "column-reverse",
            xs: "column-reverse",
          }}
          justifyContent="space-between"
          rowSpacing={2}
          sx={{ marginTop: 0 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={6}
            xl={6}
            marginTop="0px"
            paddingTop="0px !important"
            minHeight={{ xs: "500px", sm: "500px", md: "500px", lg: "660px", xl: "660px" }}
          >
            <Typography
              fontSize={{
                xs: 40,
                sm: 40,
                md: 50,
                lg: 55,
                xl: 64,
              }}
              variant="h1"
              component="h1"
              color="#FFFFFF"
              sx={{
                lineHeight: "1.1em",
                fontWeight: 900,
                textTransform: "uppercase",
                fontFamily: "Mardoto-Black",
              }}
              marginTop={{ xl: "100px", lg: "100px", md: "25px", sm: "0", xs: "0" }}
            >
              <span style={{ color: "#1FB6AB" }}>AMAA-ի </span>Կրթաթոշակի ծրագիր
            </Typography>
            <Typography
              fontSize={{
                xs: 15,
                sm: 15,
                md: 17,
                lg: 18,
                xl: 18,
              }}
              variant="h4"
              component="h4"
              color="#FFFFFF"
              sx={{ fontWeight: "500", marginBottom: "5px", marginTop: "15px" }}
            >
              Ընդհանուր տեղեկություն
            </Typography>
            <Typography
              fontSize={{
                xs: 10,
                sm: 10,
                md: 11,
                lg: 12,
                xl: 12,
              }}
              variant="p"
              component="p"
              color="#FFFFFF"
              sx={{ fontWeight: "400", marginBottom: "50px" }}
            >
              Կարեւորելով որակյալ կրթության եւ մասնագետներ ունենալու անհրաժեշտությունը՝ Ամերիկայի
              Հայ Ավետարանչական ընկերակցությունը (AMAA) իրականացնում է կրթաթոշակի ամենամյա ծրագիր։
            </Typography>

            <Link
              href="/authentication/sign-up"
              key="1"
              underline="none"
              color="#fff"
              sx={{
                padding: "10px 20px",
                backgroundColor: "#33C9BF !important",
                color: "#002B4D !important",
                width: "91px",
                height: "50px",
                border: "1px solid #002B4D !important",
                borderRadius: "10px !important",
                textTransform: "none",
                "&:hover": {
                  color: "#FFFFFF !important",
                },
              }}
            >
              Գրանցվել
            </Link>
          </Grid>
          <Grid
            item
            flex
            flexGrow={1}
            xs={12}
            sm={12}
            md={4}
            lg={6}
            xl={6}
            paddingTop="0px !important"
          >
            <Box
              marginLeft={{ xl: "100px", lg: "100px", md: "0px", sm: "0px", xs: "0px" }}
              sx={{
                backgroundImage: matches ? `url("${desktopImage}")` : `url("${mobileImage}")`,
                height: "100%",
                width: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                minHeight: "400px",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default GeneralInformation;
