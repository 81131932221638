const cards = [
  {
    id: 1,
    iconColor: (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="url(#paint0_linear_113_735)" />
        <path
          d="M24 33H29C29.2833 33 29.521 32.904 29.713 32.712C29.9043 32.5207 30 32.2833 30 32C30 31.7167 29.9043 31.479 29.713 31.287C29.521 31.0957 29.2833 31 29 31H24C23.7167 31 23.479 31.0957 23.287 31.287C23.0957 31.479 23 31.7167 23 32C23 32.2833 23.0957 32.5207 23.287 32.712C23.479 32.904 23.7167 33 24 33ZM24 29H32C32.2833 29 32.5207 28.904 32.712 28.712C32.904 28.5207 33 28.2833 33 28C33 27.7167 32.904 27.479 32.712 27.287C32.5207 27.0957 32.2833 27 32 27H24C23.7167 27 23.479 27.0957 23.287 27.287C23.0957 27.479 23 27.7167 23 28C23 28.2833 23.0957 28.5207 23.287 28.712C23.479 28.904 23.7167 29 24 29ZM24 25H32C32.2833 25 32.5207 24.904 32.712 24.712C32.904 24.5207 33 24.2833 33 24C33 23.7167 32.904 23.479 32.712 23.287C32.5207 23.0957 32.2833 23 32 23H24C23.7167 23 23.479 23.0957 23.287 23.287C23.0957 23.479 23 23.7167 23 24C23 24.2833 23.0957 24.5207 23.287 24.712C23.479 24.904 23.7167 25 24 25ZM21 35H35V21H21V35ZM21 21V35V21ZM21 37C20.45 37 19.979 36.8043 19.587 36.413C19.1957 36.021 19 35.55 19 35V21C19 20.45 19.1957 19.979 19.587 19.587C19.979 19.1957 20.45 19 21 19H25.2C25.4167 18.4 25.7793 17.9167 26.288 17.55C26.796 17.1833 27.3667 17 28 17C28.6333 17 29.2043 17.1833 29.713 17.55C30.221 17.9167 30.5833 18.4 30.8 19H35C35.55 19 36.021 19.1957 36.413 19.587C36.8043 19.979 37 20.45 37 21V35C37 35.55 36.8043 36.021 36.413 36.413C36.021 36.8043 35.55 37 35 37H21ZM28 20.25C28.2167 20.25 28.396 20.179 28.538 20.037C28.6793 19.8957 28.75 19.7167 28.75 19.5C28.75 19.2833 28.6793 19.104 28.538 18.962C28.396 18.8207 28.2167 18.75 28 18.75C27.7833 18.75 27.6043 18.8207 27.463 18.962C27.321 19.104 27.25 19.2833 27.25 19.5C27.25 19.7167 27.321 19.8957 27.463 20.037C27.6043 20.179 27.7833 20.25 28 20.25Z"
          fill="#33C9BF"
        />
        <defs>
          <linearGradient
            id="paint0_linear_113_735"
            x1="28"
            y1="0"
            x2="28"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF3F7" />
            <stop offset="1" stopColor="#EFF3F7" stopCpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    iconWhite: (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="#33C9BF" />
        <path
          d="M24 33H29C29.2833 33 29.521 32.904 29.713 32.712C29.9043 32.5207 30 32.2833 30 32C30 31.7167 29.9043 31.479 29.713 31.287C29.521 31.0957 29.2833 31 29 31H24C23.7167 31 23.479 31.0957 23.287 31.287C23.0957 31.479 23 31.7167 23 32C23 32.2833 23.0957 32.5207 23.287 32.712C23.479 32.904 23.7167 33 24 33ZM24 29H32C32.2833 29 32.5207 28.904 32.712 28.712C32.904 28.5207 33 28.2833 33 28C33 27.7167 32.904 27.479 32.712 27.287C32.5207 27.0957 32.2833 27 32 27H24C23.7167 27 23.479 27.0957 23.287 27.287C23.0957 27.479 23 27.7167 23 28C23 28.2833 23.0957 28.5207 23.287 28.712C23.479 28.904 23.7167 29 24 29ZM24 25H32C32.2833 25 32.5207 24.904 32.712 24.712C32.904 24.5207 33 24.2833 33 24C33 23.7167 32.904 23.479 32.712 23.287C32.5207 23.0957 32.2833 23 32 23H24C23.7167 23 23.479 23.0957 23.287 23.287C23.0957 23.479 23 23.7167 23 24C23 24.2833 23.0957 24.5207 23.287 24.712C23.479 24.904 23.7167 25 24 25ZM21 35H35V21H21V35ZM21 21V35V21ZM21 37C20.45 37 19.979 36.8043 19.587 36.413C19.1957 36.021 19 35.55 19 35V21C19 20.45 19.1957 19.979 19.587 19.587C19.979 19.1957 20.45 19 21 19H25.2C25.4167 18.4 25.7793 17.9167 26.288 17.55C26.796 17.1833 27.3667 17 28 17C28.6333 17 29.2043 17.1833 29.713 17.55C30.221 17.9167 30.5833 18.4 30.8 19H35C35.55 19 36.021 19.1957 36.413 19.587C36.8043 19.979 37 20.45 37 21V35C37 35.55 36.8043 36.021 36.413 36.413C36.021 36.8043 35.55 37 35 37H21ZM28 20.25C28.2167 20.25 28.396 20.179 28.538 20.037C28.6793 19.8957 28.75 19.7167 28.75 19.5C28.75 19.2833 28.6793 19.104 28.538 18.962C28.396 18.8207 28.2167 18.75 28 18.75C27.7833 18.75 27.6043 18.8207 27.463 18.962C27.321 19.104 27.25 19.2833 27.25 19.5C27.25 19.7167 27.321 19.8957 27.463 20.037C27.6043 20.179 27.7833 20.25 28 20.25Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_113_735"
            x1="28"
            y1="0"
            x2="28"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF3F7" />
            <stop offset="1" stopColor="#EFF3F7" stopCpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    cardTitle: "դիմորդի նկարը",
    cardContent: "Ավելին",
  },
  {
    id: 2,
    iconColor: (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="url(#paint0_linear_1_3)" />
        <path
          d="M27 18V20H19C17.897 20 17 20.897 17 22V34C17 35.103 17.897 36 19 36H37C38.103 36 39 35.103 39 34V22C39 20.897 38.103 20 37 20H29V18H27ZM19 22H37L37.002 34H19V22ZM25 24C24.4696 24 23.9609 24.2107 23.5858 24.5858C23.2107 24.9609 23 25.4696 23 26C23 26.5304 23.2107 27.0391 23.5858 27.4142C23.9609 27.7893 24.4696 28 25 28C25.5304 28 26.0391 27.7893 26.4142 27.4142C26.7893 27.0391 27 26.5304 27 26C27 25.4696 26.7893 24.9609 26.4142 24.5858C26.0391 24.2107 25.5304 24 25 24ZM32 25C31.4477 25 31 25.4477 31 26C31 26.5523 31.4477 27 32 27H34C34.5523 27 35 26.5523 35 26C35 25.4477 34.5523 25 34 25H32ZM25 29C22.815 29 21 29.9088 21 31.2168V32H29V31.2168C29 29.9088 27.185 29 25 29ZM32 29C31.4477 29 31 29.4477 31 30C31 30.5523 31.4477 31 32 31H34C34.5523 31 35 30.5523 35 30C35 29.4477 34.5523 29 34 29H32Z"
          fill="#33C9BF"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_3"
            x1="28"
            y1="0"
            x2="28"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF3F7" />
            <stop offset="1" stopColor="#EFF3F7" stopCpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    iconWhite: (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="#33C9BF" />
        <path
          d="M27 18V20H19C17.897 20 17 20.897 17 22V34C17 35.103 17.897 36 19 36H37C38.103 36 39 35.103 39 34V22C39 20.897 38.103 20 37 20H29V18H27ZM19 22H37L37.002 34H19V22ZM25 24C24.4696 24 23.9609 24.2107 23.5858 24.5858C23.2107 24.9609 23 25.4696 23 26C23 26.5304 23.2107 27.0391 23.5858 27.4142C23.9609 27.7893 24.4696 28 25 28C25.5304 28 26.0391 27.7893 26.4142 27.4142C26.7893 27.0391 27 26.5304 27 26C27 25.4696 26.7893 24.9609 26.4142 24.5858C26.0391 24.2107 25.5304 24 25 24ZM32 25C31.4477 25 31 25.4477 31 26C31 26.5523 31.4477 27 32 27H34C34.5523 27 35 26.5523 35 26C35 25.4477 34.5523 25 34 25H32ZM25 29C22.815 29 21 29.9088 21 31.2168V32H29V31.2168C29 29.9088 27.185 29 25 29ZM32 29C31.4477 29 31 29.4477 31 30C31 30.5523 31.4477 31 32 31H34C34.5523 31 35 30.5523 35 30C35 29.4477 34.5523 29 34 29H32Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_3"
            x1="28"
            y1="0"
            x2="28"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF3F7" />
            <stop offset="1" stopColor="#EFF3F7" stopCpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    cardTitle: "անձնագրի պատճենը",
    cardContent: "Ավելին",
  },
  {
    id: 3,
    iconColor: (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="url(#paint0_linear_1_3)" />
        <path
          d="M19 19C17.9069 19 17 19.9069 17 21V23C17 24.0931 17.9069 25 19 25H21V37H23C23 36.448 23.448 36 24 36C24.552 36 25 36.448 25 37H27C27 36.448 27.448 36 28 36C28.552 36 29 36.448 29 37H31C31 36.448 31.448 36 32 36C32.552 36 33 36.448 33 37H35V25H37C38.0931 25 39 24.0931 39 23V21C39 19.9069 38.0931 19 37 19H19ZM19 21H21V23H19V21ZM23 21H33V34H23V21ZM35 21H37V23H35V21ZM26 23C25.4477 23 25 23.4477 25 24C25 24.5523 25.4477 25 26 25H30C30.5523 25 31 24.5523 31 24C31 23.4477 30.5523 23 30 23H26ZM26 27C25.4477 27 25 27.4477 25 28C25 28.5523 25.4477 29 26 29H28C28.5523 29 29 28.5523 29 28C29 27.4477 28.5523 27 28 27H26Z"
          fill="#33C9BF"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_3"
            x1="28"
            y1="0"
            x2="28"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF3F7" />
            <stop offset="1" stopColor="#EFF3F7" stopCpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    iconWhite: (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="#33C9BF" />
        <path
          d="M19 19C17.9069 19 17 19.9069 17 21V23C17 24.0931 17.9069 25 19 25H21V37H23C23 36.448 23.448 36 24 36C24.552 36 25 36.448 25 37H27C27 36.448 27.448 36 28 36C28.552 36 29 36.448 29 37H31C31 36.448 31.448 36 32 36C32.552 36 33 36.448 33 37H35V25H37C38.0931 25 39 24.0931 39 23V21C39 19.9069 38.0931 19 37 19H19ZM19 21H21V23H19V21ZM23 21H33V34H23V21ZM35 21H37V23H35V21ZM26 23C25.4477 23 25 23.4477 25 24C25 24.5523 25.4477 25 26 25H30C30.5523 25 31 24.5523 31 24C31 23.4477 30.5523 23 30 23H26ZM26 27C25.4477 27 25 27.4477 25 28C25 28.5523 25.4477 29 26 29H28C28.5523 29 29 28.5523 29 28C29 27.4477 28.5523 27 28 27H26Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_3"
            x1="28"
            y1="0"
            x2="28"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF3F7" />
            <stop offset="1" stopColor="#EFF3F7" stopCpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    cardTitle: "տեղեկանք ուսումնական հաստատությունից",
    cardContent: "Ավելին",
  },
  {
    id: 4,
    iconColor: (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="url(#paint0_linear_1_3)" />
        <path
          d="M18 20V34C18 35.105 18.895 36 20 36H26.2773C26.6236 36.5956 27.2611 37 28 37C28.7389 37 29.3764 36.5956 29.7227 36H36C37.105 36 38 35.105 38 34V20H30C29.228 20 28.532 20.3003 28 20.7793C27.468 20.3003 26.772 20 26 20H18ZM20 22H26C26.551 22 27 22.449 27 23V34H20V22ZM30 22H36V34H29V23C29 22.449 29.449 22 30 22ZM23 24C22.4477 24 22 24.4477 22 25C22 25.5523 22.4477 26 23 26H24C24.5523 26 25 25.5523 25 25C25 24.4477 24.5523 24 24 24H23ZM32 24C31.4477 24 31 24.4477 31 25C31 25.5523 31.4477 26 32 26H33C33.5523 26 34 25.5523 34 25C34 24.4477 33.5523 24 33 24H32ZM23 27C22.4477 27 22 27.4477 22 28C22 28.5523 22.4477 29 23 29H24C24.5523 29 25 28.5523 25 28C25 27.4477 24.5523 27 24 27H23ZM32 27C31.4477 27 31 27.4477 31 28C31 28.5523 31.4477 29 32 29H33C33.5523 29 34 28.5523 34 28C34 27.4477 33.5523 27 33 27H32ZM23 30C22.4477 30 22 30.4477 22 31C22 31.5523 22.4477 32 23 32H24C24.5523 32 25 31.5523 25 31C25 30.4477 24.5523 30 24 30H23Z"
          fill="#33C9BF"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_3"
            x1="28"
            y1="0"
            x2="28"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF3F7" />
            <stop offset="1" stopColor="#EFF3F7" stopCpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    iconWhite: (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="#33C9BF" />
        <path
          d="M18 20V34C18 35.105 18.895 36 20 36H26.2773C26.6236 36.5956 27.2611 37 28 37C28.7389 37 29.3764 36.5956 29.7227 36H36C37.105 36 38 35.105 38 34V20H30C29.228 20 28.532 20.3003 28 20.7793C27.468 20.3003 26.772 20 26 20H18ZM20 22H26C26.551 22 27 22.449 27 23V34H20V22ZM30 22H36V34H29V23C29 22.449 29.449 22 30 22ZM23 24C22.4477 24 22 24.4477 22 25C22 25.5523 22.4477 26 23 26H24C24.5523 26 25 25.5523 25 25C25 24.4477 24.5523 24 24 24H23ZM32 24C31.4477 24 31 24.4477 31 25C31 25.5523 31.4477 26 32 26H33C33.5523 26 34 25.5523 34 25C34 24.4477 33.5523 24 33 24H32ZM23 27C22.4477 27 22 27.4477 22 28C22 28.5523 22.4477 29 23 29H24C24.5523 29 25 28.5523 25 28C25 27.4477 24.5523 27 24 27H23ZM32 27C31.4477 27 31 27.4477 31 28C31 28.5523 31.4477 29 32 29H33C33.5523 29 34 28.5523 34 28C34 27.4477 33.5523 27 33 27H32ZM23 30C22.4477 30 22 30.4477 22 31C22 31.5523 22.4477 32 23 32H24C24.5523 32 25 31.5523 25 31C25 30.4477 24.5523 30 24 30H23Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_3"
            x1="28"
            y1="0"
            x2="28"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF3F7" />
            <stop offset="1" stopColor="#EFF3F7" stopCpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    cardTitle: "2 տարվա ակադեմիական առաջադիմության թերթիկ",
    cardContent: "Ավելին",
  },
  {
    id: 5,
    iconColor: (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="url(#paint0_linear_1_3)" />
        <path
          d="M22 18C20.9057 18 20 18.9057 20 20V36C20 37.0943 20.9057 38 22 38H30L36 32V31.5859V20C36 18.9057 35.0943 18 34 18H22ZM22 20H34V31H29V36H22V20Z"
          fill="#33C9BF"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_3"
            x1="28"
            y1="0"
            x2="28"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF3F7" />
            <stop offset="1" stopColor="#EFF3F7" stopCpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    iconWhite: (
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="28" cy="28" r="28" fill="#33C9BF" />
        <path
          d="M22 18C20.9057 18 20 18.9057 20 20V36C20 37.0943 20.9057 38 22 38H30L36 32V31.5859V20C36 18.9057 35.0943 18 34 18H22ZM22 20H34V31H29V36H22V20Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1_3"
            x1="28"
            y1="0"
            x2="28"
            y2="56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EFF3F7" />
            <stop offset="1" stopColor="#EFF3F7" stopCpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    cardTitle: "3 բնութագրական նամակ նշանավոր անձանցից",
    cardContent: "Ավելին",
  },
];

export default cards;
