import requiredFieldsEight from "./stageEight/data";
import { requiredFieldsPersonalData } from "./stageFirst/data";
import requiredFieldsFive from "./stageFive/data";
import { requiredFieldsFour } from "./stageFour/data";
import requiredFieldsSeven from "./stageSeven/data";
import requiredFieldsSix from "./stageSix/data";
import { requiredFieldsEducation } from "./stageTwo/data";

function validate(app, validationObj) {
  if (Array.isArray(validationObj)) {
    return validationObj.every((key) => !!app[key] || app[key] === 0);
  }
  if (typeof validationObj === "object") {
    return Object.keys(validationObj).every((key) => validate(app[key], validationObj[key]));
  }
  return true;
}

// eslint-disable-next-line import/prefer-default-export
export function getCurrentStep(app, setActiveStep, activeStep) {
  let step = 1;
  const stepValidations = [
    requiredFieldsPersonalData,
    requiredFieldsEducation,
    requiredFieldsFour,
    requiredFieldsFive,
    requiredFieldsSix,
    requiredFieldsSeven,
    requiredFieldsEight,
  ];
  for (let i = 0; i < stepValidations.length; i += 1) {
    step = i + 1;
    if (!validate(app, stepValidations[i])) {
      if (activeStep > step) {
        setActiveStep(step);
      }
      break;
    }
  }
  return step;
}
