import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import typography from "assets/theme/base/typography";
import Card from "@mui/material/Card";

// icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import AssignmentTurnedInIcon from "../../../../assets/images/home-images/AssignmentTurnedInIcon.png";
import FinancialIcon from "../../../../assets/images/home-images/VectorIcon.png";

import CalendarIcon from "../../../../assets/images/home-images/CalendarToday.png";
import image from "../../../../assets/images/home-images/DSC08026 1.png";
import AMAAH4 from "../../../../components/AMAAH4";

function ScholarshipHolder() {
  return (
    <Box>
      <Container>
        <Grid container>
          {/* 2 iconed section start  */}
          <Grid item>
            <Box
              width={{
                // xs: 294,
                sm: 450,
                md: 700,
                lg: 800,
                xl: 890,
                xxl: 980,
              }}
              marginTop={{
                xs: "-50px",
                sm: "-50px",
                md: "-100px",
                lg: "-100px",
                lx: "-100px",
              }}
              marginLeft={
                {
                  // xs: "-5vw",
                  // sm: "-5vw",
                  // md: "-5vw",
                  // // lg: "-5vw",
                  // xl: "2vw",
                }
              }
              sx={{
                background: "#FFFFFF",
                height: "200px",
                // position: "relative",
                margin: "0 auto",
                borderRadius: "20px",
                paddingTop: "20px",
                textAlign: "center",
              }}
            >
              <Typography
                fontSize={{
                  xs: 12,
                  sm: 12,
                  md: 17,
                  lg: 18,
                  xl: 18,
                }}
                width={{
                  xs: "auto",
                  sm: "auto",
                  md: "auto",
                  lg: "auto",
                  xl: "auto",
                }}
                variant="h4"
                component="h4"
                color="#002B4D"
                marginBottom="45px"
                sx={{ fontWeight: "500", marginBottom: "25px" }}
              >
                Ծրագիրն իրականացվում է երկու փուլով՝
              </Typography>
              <Box
                paddingTop={{
                  xs: "89px",
                  sm: "89px",
                  md: "78px",
                  lg: "48px",
                  xl: "48px",
                }}
                sx={{
                  display: "flex",
                  margin: "0 auto",
                  borderRadius: "20px",
                  justifyContent: "space-evenly",
                }}
              >
                <Box
                  left={{
                    xs: "45px",
                    sm: "45px",
                    md: "90px",
                    lg: "147px",
                    xl: "147px",
                  }}
                  sx={{
                    display: "grid",
                    position: "relative",
                    bottom: {
                      xs: "60px",
                      sm: "60px",
                      md: "70px",
                      lg: "70px",
                      xl: "70px",
                    },
                  }}
                >
                  <Box
                    width={{
                      xs: "40px",
                      sm: "40px",
                      md: "50px",
                      lg: "50px",
                      xl: "50px",
                    }}
                    height={{
                      xs: "40px",
                      sm: "40px",
                      md: "50px",
                      lg: "50px",
                      xl: "50px",
                    }}
                    sx={{
                      marginLeft: {
                        xs: "4px",
                        sm: "4px",
                        md: 0,
                        lg: 0,
                        xl: 0,
                      },
                      background: "#002B4D",
                      borderRadius: "50px",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <AssignmentIcon
                      sx={{
                        position: "absolute",
                        top: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        left: {
                          xs: "10px",
                          sm: "10px",
                          md: "14px",
                          lg: "14px",
                          xl: "14px",
                        },
                        color: "#FFFFFF",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      height: "33px",
                      bottom: "17px",
                      width: "50px",
                      borderRadius: "50px 50px 0 0",
                      background:
                        "linear-gradient(180deg, rgba(51, 201, 191, 0.3) 0%, rgba(217, 217, 217, 0) 100%)",
                    }}
                  >
                    {" "}
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      height: "44px",
                      right: "17px",
                      bottom: "56px",
                      width: "85px",
                      borderRadius: "50px 50px 0 0",
                      background:
                        "linear-gradient(180deg, rgba(51, 201, 191, 0.3) 0%, rgba(217, 217, 217, 0) 100%)",
                    }}
                  >
                    {" "}
                  </Box>
                  <Box
                    sx={{
                      borderLeft: "2px solid #33C9BF",
                      height: "22px",
                      position: "relative",
                      left: {
                        lg: "23px",
                        md: "23px",
                        sm: "23px",
                        xs: "23px",
                      },
                      bottom: "81px",
                    }}
                  >
                    {" "}
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "50px",
                      background: "#33C9BF",
                      width: "10px",
                      height: "10px",
                      position: "relative",
                      left: "19px",
                      bottom: "81px",
                    }}
                  >
                    {" "}
                  </Box>
                  <Typography
                    variant="p"
                    fontSize={{
                      lg: 13,
                      md: 13,
                      sm: 11,
                      xs: 11,
                    }}
                    sx={{
                      width: "139px",
                      position: "relative",
                      right: "39px",
                      bottom: "73px",
                      color: "#002B4D",
                    }}
                  >
                    հայտի ներկայացում,
                  </Typography>
                </Box>
                <Box
                  width={{
                    xs: "92px",
                    sm: "92px",
                    md: "200px",
                    lg: "451px",
                    xl: "451px",
                  }}
                  sx={{
                    border: "1px dashed #33C9BF",
                    height: "1px",
                    position: "relative",
                    top: "6px",
                    right: {
                      xs: "32px",
                      sm: "32px",
                      md: "47px",
                      lg: "47px",
                      xl: "47px",
                    },
                  }}
                >
                  {" "}
                </Box>
                <Box
                  right={{
                    xs: "22px",
                    sm: "22px",
                    md: "90px",
                    lg: "147px",
                    xl: "147px",
                  }}
                  sx={{
                    display: "grid",
                    position: "relative",
                    bottom: {
                      xs: "60px",
                      sm: "60px",
                      md: "70px",
                      lg: "70px",
                      xl: "70px",
                    },
                  }}
                >
                  <Box
                    width={{
                      xs: "40px",
                      sm: "40px",
                      md: "50px",
                      lg: "50px",
                      xl: "50px",
                    }}
                    height={{
                      xs: "40px",
                      sm: "40px",
                      md: "50px",
                      lg: "50px",
                      xl: "50px",
                    }}
                    sx={{
                      marginLeft: {
                        xs: "4px",
                        sm: "4px",
                        md: 0,
                        lg: 0,
                        xl: 0,
                      },
                      background: "#002B4D",
                      borderRadius: "50px",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <PeopleAltIcon
                      sx={{
                        position: "absolute",
                        top: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        left: {
                          xs: "10px",
                          sm: "10px",
                          md: "14px",
                          lg: "14px",
                          xl: "14px",
                        },
                        color: "#FFFFFF",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      height: "33px",
                      bottom: "17px",
                      width: "50px",
                      borderRadius: "50px 50px 0 0",
                      background:
                        "linear-gradient(180deg, rgba(51, 201, 191, 0.3) 0%, rgba(217, 217, 217, 0) 100%)",
                    }}
                  >
                    {" "}
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      height: "44px",
                      right: "17px",
                      bottom: "56px",
                      width: "85px",
                      borderRadius: "50px 50px 0 0",
                      background:
                        "linear-gradient(180deg, rgba(51, 201, 191, 0.3) 0%, rgba(217, 217, 217, 0) 100%)",
                    }}
                  >
                    {" "}
                  </Box>
                  <Box
                    sx={{
                      borderLeft: "2px solid #33C9BF",
                      height: "22px",
                      position: "relative",
                      left: "23px",
                      bottom: "81px",
                    }}
                  >
                    {" "}
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "50px",
                      background: "#33C9BF",
                      width: "10px",
                      height: "10px",
                      position: "relative",
                      left: "19px",
                      bottom: "81px",
                    }}
                  >
                    {" "}
                  </Box>
                  <Typography
                    variant="p"
                    fontSize={{
                      lg: 13,
                      md: 13,
                      sm: 11,
                      xs: 11,
                    }}
                    sx={{
                      width: "67px",
                      position: "relative",
                      right: "12px",
                      color: "#002B4D",
                      bottom: "73px",
                    }}
                  >
                    հարցազրույց։
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          {/* 2 iconed section end  */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography
              marginTop={{
                xs: "15px",
                sm: "15px",
                md: "20px",
                lg: "20px",
                lx: "20px",
              }}
              fontSize={{
                xs: 10,
                sm: 10,
                md: 11,
                lg: 12,
                xl: 12,
              }}
              variant="p"
              component="p"
              color="#002B4D"
              align="left"
              sx={{ fontWeight: "400", marginBottom: "50px", maxWidth: "800px" }}
            >
              AMAA-ի Կրթաթոշակի ծրագրին կարող են դիմել Հայաստանի պետական ու միջպետական բուհերի՝
              ուսուցման առկա համակարգում սովորող կամ սովորել պատրաստվող այն ուսանողներն, ովքեր
              ունեն՝
            </Typography>
          </Grid>
          {/* 3 iconed section start  */}
          <Grid
            item
            display={{
              xs: "grid",
              sm: "grid",
              md: "flex",
              lg: "flex",
              xl: "flex",
            }}
          >
            <Grid item>
              <Card
                sx={{
                  width: {
                    xs: "300px",
                    sm: "300px",
                    md: "226px",
                    lg: "226px",
                    xl: "226px",
                  },
                  height: "88px",
                  marginBottom: "15px",
                  background: "#00233F",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px",
                    paddingLeft: "30px",
                  }}
                >
                  <Box
                    component="img"
                    width={{
                      xs: 23,
                      sm: 23,
                      md: 18,
                      lg: 23,
                      xl: 23,
                    }}
                    height={{
                      xs: 30,
                      sm: 30,
                      md: 21,
                      lg: 30,
                      xl: 30,
                    }}
                    alt="icon"
                    src={AssignmentTurnedInIcon}
                  />
                  <Typography variant="span" color="#FFFFFF" sx={{ fontSize: "12px" }}>
                    բարձր ակադեմիական առաջադիմություն,
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              sx={{
                marginLeft: { xs: 0, sm: 0, md: "16px", lg: "16px", xl: "16px" },
              }}
            >
              <Card
                sx={{
                  width: {
                    xs: "300px",
                    sm: "300px",
                    md: "178px",
                    lg: "178px",
                    xl: "178px",
                  },
                  height: "88px",
                  marginBottom: "15px",
                  background: "#00233F",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    gap: "15px",
                    paddingLeft: "30px",
                  }}
                >
                  <Box
                    component="img"
                    width={{
                      xs: 23,
                      sm: 23,
                      md: 18,
                      lg: 23,
                      xl: 23,
                    }}
                    height={{
                      xs: 30,
                      sm: 30,
                      md: 21,
                      lg: 30,
                      xl: 30,
                    }}
                    alt="icon"
                    src={FinancialIcon}
                  />
                  <Typography variant="p" color="#FFFFFF" sx={{ fontSize: "12px" }}>
                    ֆինանսական կարիք։
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              sx={{
                marginLeft: { xs: 0, sm: 0, md: "16px", lg: "16px", xl: "16px" },
              }}
            >
              <Card
                sx={{
                  width: {
                    xs: "300px",
                    sm: "300px",
                    md: "310px",
                    lg: "310px",
                    xl: "310px",
                  },
                  height: "88px",
                  marginBottom: "15px",
                  background: "#1FB6AB",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px",
                    paddingLeft: "30.67px",
                  }}
                >
                  <Box
                    component="img"
                    width={30}
                    height={{
                      xs: 30,
                      sm: 30,
                      md: 21,
                      lg: 30,
                      xl: 30,
                    }}
                    alt="icon"
                    src={CalendarIcon}
                  />
                  <Typography variant="p" color="#FFFFFF" sx={{ fontSize: "12px" }}>
                    Հայտերի ընդունման վերջնաժամկետ՝ սեպտեմբերի 30:
                  </Typography>
                </Box>
              </Card>
            </Grid>{" "}
          </Grid>
          {/* 3 iconed section start  */}
        </Grid>
      </Container>

      <Box
        marginTop={{
          xs: 15,
          sm: 15,
          md: 18,
          lg: 8,
          xl: 8,
        }}
        display={{
          xs: "grid",
          sm: "grid",
          md: "grid",
          lg: "flex",
          xl: "flex",
        }}
        padding={{
          xs: "58px 37px",
          sm: "20px 150px",
          md: "20px 120px",
          lg: "120px 120px",
          xl: "120px 120px",
        }}
        height={{
          xs: 940,
          sm: 940,
          md: 800,
          lg: 400,
          xl: 400,
        }}
        sx={{ background: "#EFF3F7" }}
      >
        <Container>
          <Grid
            container
            rowSpacing={12}
            sx={{
              flexWrap: "wrap",
              gridColumn: 1,
              gridRow: 2,
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={3}
              xl={3}
              sx={{
                display: "grid",
                padding: "0 !important",
                marginTop: "50px",
              }}
              justifyContent={{
                xs: "revert",
                sm: "revert",
                md: "center",
                lg: "revert",
                xl: "revert",
              }}
            >
              <Box
                width={{
                  xs: "265px",
                  sm: "265px",
                  md: "200px",
                  lg: "200px",
                  xl: "200px",
                }}
                height={{
                  xs: "249px",
                  sm: "249px",
                  md: "200px",
                  lg: "200px",
                  xl: "200px",
                }}
                sx={{
                  left: "1px",
                  position: "relative",
                  top: "32px",
                  background: "#3255707a",
                  opacity: 5,
                  borderRadius: "10px",
                }}
              >
                <Box
                  width={{
                    xs: "265px",
                    sm: "265px",
                    md: "200px",
                    lg: "200px",
                    xl: "200px",
                  }}
                  height={{
                    xs: "249px",
                    sm: "249px",
                    md: "200px",
                    lg: "200px",
                    xl: "200px",
                  }}
                  sx={{
                    position: "relative",
                    top: "-18px",
                    left: "21px",
                    background: "#325570",
                    opacity: 5,
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    component="img"
                    width={{
                      xs: "257px",
                      sm: "257px",
                      md: "200px",
                      lg: "200px",
                      xl: "200px",
                    }}
                    height={{
                      xs: "247px",
                      sm: "247px",
                      md: "200px",
                      lg: "200px",
                      xl: "200px",
                    }}
                    sx={{
                      borderRadius: "10px",
                      position: "absolute",
                      zIndex: 1,
                      right: 0,
                      bottom: "144px",
                      left: {
                        xs: "27px",
                        sm: "27px",
                        md: "19px",
                        lg: "22px",
                        xl: "22px",
                      },
                      top: "-23px",
                      marginBottom: "50px",
                    }}
                    alt="Image of part of apply the program"
                    src={`${image}`}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={9}
              xl={9}
              sx={{ padding: "0 !important", marginTop: "50px" }}
            >
              <AMAAH4
                fontSize={{
                  xs: 24,
                  sm: 24,
                  md: 32,
                }}
                width={{
                  xs: 278,
                  sm: 278,
                  md: 300,
                  lg: 556,
                  xl: 556,
                }}
                sx={{ fontWeight: "500", marginBottom: "15px", fontFamily: "Mardoto-Medium" }}
              >
                Հաստատված կրթաթոշակառուները պետք է՝
              </AMAAH4>

              <Box
                display={{
                  xs: "grid",
                  sm: "grid",
                  md: "grid",
                  lg: "flex",
                  xl: "flex",
                }}
                width={{
                  xs: 300,
                  sm: 300,
                  md: 300,
                  lg: 710,
                  xl: 710,
                }}
                sx={{ alignItems: "start", justifyContent: "space-between" }}
              >
                <Box
                  borderRight={{
                    lg: "1px solid #CCD4DB",
                    md: "none",
                    sm: "none",
                    xs: "none",
                  }}
                  borderBottom={{
                    lg: "none",
                    md: "1px solid #CCD4DB",
                    sm: "1px solid #CCD4DB",
                    xs: "1px solid #CCD4DB",
                  }}
                  sx={{
                    display: "grid",
                    height: "150px",
                    marginBottom: "12px",
                  }}
                >
                  <CheckCircleOutlineIcon sx={{ color: "#1FB6AB" }} />
                  <Typography
                    variant="h6"
                    fontSize={{
                      lg: 16,
                      md: 16,
                      sm: 20,
                      xs: 12,
                    }}
                    sx={{
                      color: "#002B4D",
                      textAlign: "left",
                      fontFamily: "Mardoto-Medium",
                      fontSize: "18px",
                    }}
                  >
                    լրացնեն
                  </Typography>
                  <Typography
                    variant="p"
                    fontSize={{
                      lg: 13,
                      md: 13,
                      sm: 20,
                      xs: 12,
                    }}
                    width={{
                      xs: 157,
                      sm: 157,
                      md: 157,
                      lg: 157,
                      xl: 157,
                    }}
                    sx={{
                      color: "#325570",
                      textAlign: "left",
                      fontFamily: typography.fontFamily,
                      fontSize: "16px",
                    }}
                  >
                    «Ուսանողի կարիքի գնահատման» ձևաթուղթը
                  </Typography>
                </Box>
                <Box
                  borderRight={{
                    lg: "1px solid #CCD4DB",
                    md: "none",
                    sm: "none",
                    xs: "none",
                  }}
                  borderBottom={{
                    lg: "none",
                    md: "1px solid #CCD4DB",
                    sm: "1px solid #CCD4DB",
                    xs: "1px solid #CCD4DB",
                  }}
                  sx={{ display: "grid", height: "120px", marginBottom: "12px" }}
                >
                  <CheckCircleOutlineIcon sx={{ color: "#1FB6AB" }} />
                  <Typography
                    variant="p"
                    fontSize={{
                      lg: 16,
                      md: 16,
                      sm: 20,
                      xs: 12,
                    }}
                    sx={{
                      color: "#002B4D",
                      textAlign: "left",
                      fontFamily: "Mardoto-Medium",
                      fontSize: "18px",
                    }}
                  >
                    հաստատեն
                  </Typography>
                  <Typography
                    variant="p"
                    fontSize={{
                      lg: 13,
                      md: 13,
                      sm: 20,
                      xs: 12,
                    }}
                    width={{
                      xs: 157,
                      sm: 157,
                      md: 157,
                      lg: 157,
                      xl: 157,
                    }}
                    sx={{
                      color: "#325570",
                      textAlign: "left",
                      fontFamily: typography.fontFamily,
                      fontSize: "16px",
                    }}
                  >
                    «Կրթաթոշակառուի հայտարարությունը»
                  </Typography>
                </Box>
                <Box sx={{ display: "grid", height: "150px" }}>
                  <CheckCircleOutlineIcon sx={{ color: "#1FB6AB" }} />
                  <Typography
                    variant="p"
                    fontSize={{
                      lg: 16,
                      md: 16,
                      sm: 20,
                      xs: 12,
                    }}
                    sx={{
                      color: "#002B4D",
                      textAlign: "left",
                      fontFamily: "Mardoto-Medium",
                      fontSize: "18px",
                    }}
                  >
                    տվյալ ուստարվա ավարտին տրամադրեն
                  </Typography>
                  <Typography
                    variant="p"
                    fontSize={{
                      lg: 13,
                      md: 13,
                      sm: 20,
                      xs: 12,
                    }}
                    width={{
                      xs: 197,
                      sm: 197,
                      md: 197,
                      lg: 197,
                      xl: 197,
                    }}
                    sx={{
                      color: "#325570",
                      textAlign: "left",
                      fontFamily: typography.fontFamily,
                      fontSize: "16px",
                    }}
                  >
                    հաշվետվություն իրենց ակադեմիական և անձնային աճի վերաբերյալ
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default ScholarshipHolder;
