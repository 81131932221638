const optionsYears = () => {
  const years = [];
  // eslint-disable-next-line no-plusplus
  for (let i = new Date().getFullYear() - 25; i <= new Date().getFullYear(); i++) {
    years.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  return years;
};

export const requiredFieldsFour = {
  financial: [
    "finance_information",
    "prev_revenue",
    "home_rented",
    "other_finance",
    "another_part",
  ],
};

export default optionsYears;
