export const optionsCountry = [{ id: 0, value: "Հայաստան", label: "Հայաստան" }];

export const optionsRegion = [
  { value: "Արագածոտն", label: "Արագածոտն" },
  { value: "Արարատ", label: "Արարատ" },
  { value: "Արմավիր", label: "Արմավիր" },
  { value: "Գեղարքունիք", label: "Գեղարքունիք" },
  { value: "Կոտայք", label: "Կոտայք" },
  { value: "Լոռի", label: "Լոռի" },
  { value: "Շիրակ", label: "Շիրակ" },
  { value: "Սյունիք", label: "Սյունիք" },
  { value: "Տավուշ", label: "Տավուշ" },
  { value: "Վայոց ձոր", label: "Վայոց ձոր" },
  { value: "Երևան", label: "Երևան" },
  { value: "Արցախ", label: "Արցախ" },
];

export const requiredFieldsPersonalData = [
  "firstName",
  "lastName",
  "family",
  "fullNameLatin",
  "passportNumber",
  "social_card",
  "country",
  "region",
  "cityVillage",
  "address",
  "phone",
  "email",
  "birthday",
  "gender",
  "marriage_status",
];
