export const optionsInstitutions = [
  {
    id: 0,
    value: "ԵՐԵՎԱՆԻ ՊԵՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ԵՐԵՎԱՆԻ ՊԵՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  { id: 1, value: "ԵՊՀ ԻՋԵՎԱՆԻ ՄԱՍՆԱՃՅՈՒՂ", label: "ԵՊՀ ԻՋԵՎԱՆԻ ՄԱՍՆԱՃՅՈՒՂ" },
  {
    id: 2,
    value: "ՀԱՅԱՍՏԱՆԻ ԱԶԳԱՅԻՆ ՊՈԼԻՏԵԽՆԻԿԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ՀԱՅԱՍՏԱՆԻ ԱԶԳԱՅԻՆ ՊՈԼԻՏԵԽՆԻԿԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  { id: 3, value: "ՀԱՊՀ ԳՅՈՒՄՐՈՒ ՄԱՍՆԱՃՅՈՒՂ", label: "ՀԱՊՀ ԳՅՈՒՄՐՈՒ ՄԱՍՆԱՃՅՈՒՂ" },
  { id: 4, value: "ՀԱՊՀ ՎԱՆԱՁՈՐԻ ՄԱՍՆԱՃՅՈՒՂ", label: "ՀԱՊՀ ՎԱՆԱՁՈՐԻ ՄԱՍՆԱՃՅՈՒՂ" },
  { id: 5, value: "ՀԱՊՀ ԿԱՊԱՆԻ ՄԱՍՆԱՃՅՈՒՂ", label: "ՀԱՊՀ ԿԱՊԱՆԻ ՄԱՍՆԱՃՅՈՒՂ" },
  {
    id: 6,
    value: "ՃԱՐՏԱՐԱՊԵՏՈՒԹՅԱՆ ԵՎ ՇԻՆԱՐԱՐՈՒԹՅԱՆ ՀԱՅԱՍՏԱՆԻ ԱԶԳԱՅԻՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ՃԱՐՏԱՐԱՊԵՏՈՒԹՅԱՆ ԵՎ ՇԻՆԱՐԱՐՈՒԹՅԱՆ ՀԱՅԱՍՏԱՆԻ ԱԶԳԱՅԻՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  {
    id: 7,
    value: "ՀԱՅԱՍՏԱՆԻ ՊԵՏԱԿԱՆ ՏՆՏԵՍԱԳԻՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ՀԱՅԱՍՏԱՆԻ ՊԵՏԱԿԱՆ ՏՆՏԵՍԱԳԻՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  { id: 8, value: "ՀՊՏՀ-Ի ԳՅՈՒՄՐՈՒ ՄԱՍՆԱՃՅՈՒՂ", label: "ՀՊՏՀ-Ի ԳՅՈՒՄՐՈՒ ՄԱՍՆԱՃՅՈՒՂ" },
  //   { value: "ՀՊՏՀ-Ի ԵՂԵԳՆԱՁՈՐԻ ՄԱՍՆԱՃՅՈՒՂ", label: "ՀՊՏՀ-Ի ԵՂԵԳՆԱՁՈՐԻ ՄԱՍՆԱՃՅՈՒՂ" },
  {
    id: 9,
    value: "Խ.ԱԲՈՎՅԱՆԻ ԱՆՎԱՆ ՀԱՅԿԱԿԱՆ ՊԵՏԱԿԱՆ ՄԱՆԿԱՎԱՐԺԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "Խ.ԱԲՈՎՅԱՆԻ ԱՆՎԱՆ ՀԱՅԿԱԿԱՆ ՊԵՏԱԿԱՆ ՄԱՆԿԱՎԱՐԺԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  {
    id: 10,
    value: "ԵՐԵՎԱՆԻ Վ.ԲՐՅՈՒՍՈՎԻ ԱՆՎԱՆ ՊԵՏԱԿԱՆ ԼԵԶՎԱՀԱՍԱՐԱԿԱԳԻՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ԵՐԵՎԱՆԻ Վ.ԲՐՅՈՒՍՈՎԻ ԱՆՎԱՆ ՊԵՏԱԿԱՆ ԼԵԶՎԱՀԱՍԱՐԱԿԱԳԻՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  {
    id: 11,
    value: "ՇԻՐԱԿԻ Մ.ՆԱԼԲԱՆԴՅԱՆԻ ԱՆՎԱՆ ՊԵՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ՇԻՐԱԿԻ Մ.ՆԱԼԲԱՆԴՅԱՆԻ ԱՆՎԱՆ ՊԵՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  {
    id: 12,
    value: "ՎԱՆԱՁՈՐԻ Հ.ԹՈՒՄԱՆՅԱՆԻ ԱՆՎԱՆ ՊԵՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ՎԱՆԱՁՈՐԻ Հ.ԹՈՒՄԱՆՅԱՆԻ ԱՆՎԱՆ ՊԵՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  {
    id: 13,
    value: "ԵՐԵՎԱՆԻ ԿՈՄԻՏԱՍԻ ԱՆՎԱՆ ՊԵՏԱԿԱՆ ԿՈՆՍԵՐՎԱՏՈՐԻԱ",
    label: "ԵՐԵՎԱՆԻ ԿՈՄԻՏԱՍԻ ԱՆՎԱՆ ՊԵՏԱԿԱՆ ԿՈՆՍԵՐՎԱՏՈՐԻԱ",
  },
  { id: 14, value: "ԵՊԿ ԳՅՈՒՄՐՈՒ ՄԱՍՆԱՃՅՈՒՂ", label: "ԵՊԿ ԳՅՈՒՄՐՈՒ ՄԱՍՆԱՃՅՈՒՂ" },
  {
    id: 15,
    value: "ՀԱՅԱՍՏԱՆԻ ԳԵՂԱՐՎԵՍՏԻ ՊԵՏԱԿԱՆ ԱԿԱԴԵՄԻԱ",
    label: "ՀԱՅԱՍՏԱՆԻ ԳԵՂԱՐՎԵՍՏԻ ՊԵՏԱԿԱՆ ԱԿԱԴԵՄԻԱ",
  },
  { id: 16, value: "ՀԳԱ ԳՅՈՒՄՐԻԻ ՄԱՍՆԱՃՅՈՒՂ", label: "ՀԳԱ ԳՅՈՒՄՐԻԻ ՄԱՍՆԱՃՅՈՒՂ" },
  //   { value: "ՀԳԱ ԴԻԼԻՋԱՆԻ ՄԱՍՆԱՃՅՈՒՂ", label: "ՀԳԱ ԴԻԼԻՋԱՆԻ ՄԱՍՆԱՃՅՈՒՂ" },
  {
    id: 17,
    value: "ԵՐԵՎԱՆԻ ԹԱՏՐՈՆԻ ԵՎ ԿԻՆՈՅԻ ՊԵՏԱԿԱՆ ԻՆՍՏԻՏՈՒՏ",
    label: "ԵՐԵՎԱՆԻ ԹԱՏՐՈՆԻ ԵՎ ԿԻՆՈՅԻ ՊԵՏԱԿԱՆ ԻՆՍՏԻՏՈՒՏ",
  },
  { id: 18, value: "ԵԹԿԻ ԳՅՈՒՄՐՈՒ ՄԱՍՆԱՃՅՈՒՂ", label: "ԵԹԿԻ ԳՅՈՒՄՐՈՒ ՄԱՍՆԱՃՅՈՒՂ" },
  { id: 19, value: "ԵԹԿԻ ՎԱՆԱՁՈՐԻ ՄԱՍՆԱՃՅՈՒՂ", label: "ԵԹԿԻ ՎԱՆԱՁՈՐԻ ՄԱՍՆԱՃՅՈՒՂ" },
  { id: 20, value: "ԵԹԿԻ ՎԱՆԱՁՈՐԻ ՄԱՍՆԱՃՅՈՒՂ", label: "ԵԹԿԻ ՎԱՆԱՁՈՐԻ ՄԱՍՆԱՃՅՈՒՂ" },
  {
    id: 21,
    value: "ԵՐԵՎԱՆԻ Մ.ՀԵՐԱՑՈՒ ԱՆՎԱՆ ՊԵՏԱԿԱՆ ԲԺՇԿԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ԵՐԵՎԱՆԻ Մ.ՀԵՐԱՑՈՒ ԱՆՎԱՆ ՊԵՏԱԿԱՆ ԲԺՇԿԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  {
    id: 22,
    value: "ՀԱՅԱՍՏԱՆԻ ՖԻԶԻԿԱԿԱՆ ԿՈՒԼՏՈՒՐԱՅԻ ԵՎ ՍՊՈՐՏԻ ՊԵՏԱԿԱՆ ԻՆՍՏԻՏՈՒՏ",
    label: "ՀԱՅԱՍՏԱՆԻ ՖԻԶԻԿԱԿԱՆ ԿՈՒԼՏՈՒՐԱՅԻ ԵՎ ՍՊՈՐՏԻ ՊԵՏԱԿԱՆ ԻՆՍՏԻՏՈՒՏ",
  },
  {
    id: 23,
    value: "ԳԱՎԱՌԻ ՊԵՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ԳԱՎԱՌԻ ՊԵՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  {
    id: 24,
    value: "ՀԱՅԱՍՏԱՆԻ ԱԶԳԱՅԻՆ ԱԳՐԱՐԱՅԻՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ՀԱՅԱՍՏԱՆԻ ԱԶԳԱՅԻՆ ԱԳՐԱՐԱՅԻՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  { id: 25, value: "ՀԱԱՀ ՍԻՍԻԱՆԻ ՄԱՍՆԱՃՅՈՒՂ", label: "ՀԱԱՀ ՍԻՍԻԱՆԻ ՄԱՍՆԱՃՅՈՒՂ" },
  { id: 26, value: "ՀԱԱՀ ՎԱՆԱՁՈՐԻ ՄԱՍՆԱՃՅՈՒՂ", label: "ՀԱԱՀ ՎԱՆԱՁՈՐԻ ՄԱՍՆԱՃՅՈՒՂ" },
  { id: 27, value: "ԳՈՐԻՍԻ ՊԵՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ", label: "ԳՈՐԻՍԻ ՊԵՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ" },
  { id: 28, value: "ՀԱՅ-ՌՈՒՍԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ", label: "ՀԱՅ-ՌՈՒՍԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ" },
  {
    id: 29,
    value: "ՀԱՅԱՍՏԱՆԻ ԱՄԵՐԻԿՅԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ՀԱՅԱՍՏԱՆԻ ԱՄԵՐԻԿՅԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  {
    id: 30,
    value: "ՀԱՅԱՍՏԱՆՈՒՄ ՖՐԱՆՍԻԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ՀԱՅԱՍՏԱՆՈՒՄ ՖՐԱՆՍԻԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  { id: 31, value: "ԵՎՐՈՊԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ", label: "ԵՎՐՈՊԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ" },
  {
    id: 32,
    value: "ՀՀ ԳԱԱ ԳԻՏԱԿՐԹԱԿԱՆ ՄԻՋԱԶԳԱՅԻՆ ԿԵՆՏՐՈՆ",
    label: "ՀՀ ԳԱԱ ԳԻՏԱԿՐԹԱԿԱՆ ՄԻՋԱԶԳԱՅԻՆ ԿԵՆՏՐՈՆ",
  },
  { id: 33, value: "ՀՀ ՊԵՏԱԿԱՆ ԿԱՌԱՎԱՐՄԱՆ ԱԿԱԴԵՄԻԱ", label: "ՀՀ ՊԵՏԱԿԱՆ ԿԱՌԱՎԱՐՄԱՆ ԱԿԱԴԵՄԻԱ" },
  {
    id: 34,
    value: "ՀՀ ԱԻՆ ՃԳՆԱԺԱՄԱՅԻՆ ԿԱՌԱՎԱՐՄԱՆ ՊԵՏԱԿԱՆ ԱԿԱԴԵՄԻԱ",
    label: "ՀՀ ԱԻՆ ՃԳՆԱԺԱՄԱՅԻՆ ԿԱՌԱՎԱՐՄԱՆ ՊԵՏԱԿԱՆ ԱԿԱԴԵՄԻԱ",
  },
  { id: 35, value: "ՀՀ ՈՍՏԻԿԱՆՈՒԹՅԱՆ ԿՐԹԱՀԱՄԱԼԻՐ", label: "ՀՀ ՈՍՏԻԿԱՆՈՒԹՅԱՆ ԿՐԹԱՀԱՄԱԼԻՐ" },
  {
    id: 36,
    value: "ՀՀ ՊՆ ՎԱԶԳԵՆ ՍԱՐԳՍՅԱՆԻ ԱՆՎԱՆ ՌԱԶՄԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ՀՀ ՊՆ ՎԱԶԳԵՆ ՍԱՐԳՍՅԱՆԻ ԱՆՎԱՆ ՌԱԶՄԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  {
    id: 37,
    value: "ՀՀ ՊՆ ԱՐՄԵՆԱԿ ԽԱՆՓԵՐՅԱՆՑԻ ԱՆՎԱՆ ՌԱԶՄԱԿԱՆ ԱՎԻԱՑԻՈՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ՀՀ ՊՆ ԱՐՄԵՆԱԿ ԽԱՆՓԵՐՅԱՆՑԻ ԱՆՎԱՆ ՌԱԶՄԱԿԱՆ ԱՎԻԱՑԻՈՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  {
    id: 38,
    value: "ՀՀ ՊՆ ՊԱՇՏՊԱՆԱԿԱՆ ԱԶԳԱՅԻՆ ՀԵՏԱԶՈՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
    label: "ՀՀ ՊՆ ՊԱՇՏՊԱՆԱԿԱՆ ԱԶԳԱՅԻՆ ՀԵՏԱԶՈՏԱԿԱՆ ՀԱՄԱԼՍԱՐԱՆ",
  },
  { id: 39, value: "Այլ", label: "Այլ" },
];

export const optionsUnit = [
  { id: 0, value: "4", label: "4" },
  { id: 1, value: "10", label: "10" },
  { id: 2, value: "20", label: "20" },
  { id: 3, value: "100", label: "100" },
];

export const requiredFieldsEducation = {
  education: [
    "education_history",
    "ratings_last_year",
    "ratings_year_before",
    "ratings_school",
    "university_name",
    "university_department",
    "university_adress",
    "university_phone",
    "university_fee_text",
    "university_fee_number",
    "help_other_text",
    "help_other_number",
    "help_job_text",
    "help_job_number",
    "help_parents_text",
    "help_parents_number",
  ],
};
