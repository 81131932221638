/* eslint-disable no-underscore-dangle */
// @mui material components
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

// @mui material styles components
import { useTheme } from "@mui/material/styles";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Navbar from "layouts/home/components1/navbar";
import Footer from "layouts/home/components1/footer";

import { getUserAllApplications, createApplication } from "hooks/allRequests/applicationApis";
import { ZonedDate } from "@progress/kendo-date-math";

import { getCurrentStep } from "layouts/dataEntryStages/hellperFunction";

// Stages of registracion
import StageFirst from "../dataEntryStages/stageFirst";
import StageTwo from "../dataEntryStages/stageTwo";
import StageFour from "../dataEntryStages/stageFour";
import StageFive from "../dataEntryStages/stageFive";
import StageSix from "../dataEntryStages/stageSix";
import StageSeven from "../dataEntryStages/stageSeven";
import StageEight from "../dataEntryStages/stageEight";

// Mobile Stepper
import MobileStepper from "../../components/Stepper/mobile";
import Stepper from "../../components/Stepper/index";

function PersonalInformation() {
  const [activeStep, setActiveStep] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleNext = () => {
    if (activeStep === 7) {
      setActiveStep(activeStep);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleCancel = () => {
    if (activeStep === 1) {
      setActiveStep(activeStep);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  useEffect(async () => {
    // const actvieStep = localStorage.getItem("actvieStep");
    // if (actvieStep) setActiveStep(activeStep);
    const allAps = await getUserAllApplications();
    let foundObj = {};
    if (allAps) {
      const nowadaysYear = new Date().getFullYear();
      foundObj = allAps.find((obj) => {
        const date = new Date(obj.createdAt);
        const tzCreatedDate = ZonedDate.fromUTCDate(date, "Asia/Yerevan");
        const createdAtYear = tzCreatedDate.getFullYear();
        return createdAtYear === nowadaysYear;
      });
      if (foundObj) {
        if (foundObj?.status !== "created" && foundObj?.status !== "returned") {
          localStorage.setItem("applicationId", foundObj?._id);
          navigate("/submittedApplication");
        } else {
          getCurrentStep(foundObj, setActiveStep, activeStep);
        }
      } else {
        const createdApplication = await createApplication();
        if (createdApplication) {
          foundObj = createdApplication;
        }
      }
    } else {
      const createdApplication = await createApplication();
      if (createdApplication) {
        foundObj = createdApplication;
      }
    }
    localStorage.setItem("applicationId", foundObj._id);
    setLoading(false);
  }, []);
  const stages = () => {
    switch (activeStep) {
      case 1:
        return (
          <StageFirst
            handleNext={handleNext}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        );
      case 2:
        return (
          <StageTwo
            handleNext={handleNext}
            handleCancel={handleCancel}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        );
      case 3:
        return (
          <StageFour
            handleNext={handleNext}
            handleCancel={handleCancel}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        );
      case 4:
        return (
          <StageFive
            handleNext={handleNext}
            handleCancel={handleCancel}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        );
      case 5:
        return (
          <StageSix
            handleNext={handleNext}
            handleCancel={handleCancel}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        );
      case 6:
        return (
          <StageSeven
            handleNext={handleNext}
            handleCancel={handleCancel}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        );
      case 7:
        return (
          <StageEight
            handleCancel={handleCancel}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
          />
        );
      default:
        return (
          <StageFirst handleNext={handleNext} handleCancel={handleCancel} activeStep={activeStep} />
        );
    }
  };
  return (
    <PageLayout>
      <MDBox pt={6} pb={3}>
        <Navbar />
        <Grid container spacing={3}>
          {isMobile ? <Stepper stage={activeStep} /> : <MobileStepper stage={activeStep} />}
          {loading ? "" : stages()}
        </Grid>
        <Footer />
      </MDBox>
    </PageLayout>
  );
}

export default PersonalInformation;
