const steps = [
  {
    page: "Էջ 1",
    step: "Անձնական \n տվյալներ",
  },
  {
    page: "Էջ 2",
    step: "Կրթություն",
  },
  {
    page: "Էջ 3",
    step: "Ֆինանսական \n տեղեկություն",
  },
  {
    page: "Էջ 4",
    step: "Այլ \n տեղեկություն",
  },
  {
    page: "Էջ 5",
    step: "Ինքնա- \n կենսագրություն",
  },
  {
    page: "Էջ 6",
    step: "Էսսե-ակնարկ",
  },
  {
    page: "Էջ 7",
    step: "Փաստաթղթեր",
  },
];

export default steps;
